import React from 'react';
import { Divider } from 'wix-ui-tpa/Divider';
import { classes } from './Header.st.css';
import { AccessibilityHtmlTags } from '../../../../utils/AccessibilityHtmlTags.const';
import { Header as FormHeader } from '@wix/ambassador-services-catalog-server/http';
import { HeaderDataHooks } from './dataHooks';
import Text, { TextType } from '../../Text/Text';
import { SectionTitle } from '../SectionHeader/SectionTitle';

export const Header: React.FC<FormHeader> = ({
  title,
  description,
  isDescriptionHidden,
}) => {
  const showDescription = !isDescriptionHidden;

  return (
    <div className={classes.root}>
      <SectionTitle label={title!} />
      {showDescription ? (
        <Text
          type={TextType.Primary}
          className={classes.description}
          data-hook={HeaderDataHooks.DESCRIPTION}
          tagName={AccessibilityHtmlTags.SecondaryHeading}
        >
          {description}
        </Text>
      ) : null}
    </div>
  );
};
