import React from 'react';
import { classes, st } from './BookButton.st.css';
import { BookButtonDataHooks } from './dataHooks';
import { useEnvironment, useTranslation } from '@wix/yoshi-flow-editor';
import { Button, SIZE } from 'wix-ui-tpa/Button';
import { useFormActions } from '../../Hooks/useFormActions';
import { PaymentType } from '../../../../utils/mappers/service.mapper';
import { useSettings } from '@wix/yoshi-flow-editor/build/cjs/tpa-settings/react';
import { ISettingsContextValue } from '@wix/tpa-settings';
import settingsParams from '../../settingsParams';
import { ActionLabels } from '@wix/ambassador-services-catalog-server/http';

export interface BookButtonProps {
  isPendingApprovalFlow: boolean;
  actionLabels: ActionLabels;
  paymentTypes: PaymentType[];
}
const BookButton: React.FC<BookButtonProps> = ({
  isPendingApprovalFlow,
  actionLabels,
  paymentTypes,
}) => {
  const { t } = useTranslation();
  const { onSubmit, submitForm } = useFormActions();
  const { isMobile } = useEnvironment();
  const settings = useSettings();
  const actionLabel = getActionLabel(
    isPendingApprovalFlow,
    actionLabels,
    paymentTypes,
    settings,
  );

  return (
    <div className={st(classes.root, { isMobile })}>
      <Button
        size={SIZE.medium}
        className={classes.bookButton}
        data-hook={BookButtonDataHooks.ACTION_BUTTON}
        upgrade
        onClick={() => {
          const submissionResponse = submitForm?.();
          if (submissionResponse) {
            onSubmit(submissionResponse);
          }
        }}
      >
        {t(actionLabel)}
      </Button>
    </div>
  );
};

const getActionLabel = (
  isPendingApprovalFlow: boolean,
  actionLabels: ActionLabels,
  paymentTypes: PaymentType[],
  settings: ISettingsContextValue,
): string => {
  if (isPendingApprovalFlow) {
    return (
      settings.get(settingsParams.requestBookingButtonText) ||
      actionLabels?.bookingRequestApprovalLabel!
    );
  } else if (canBePaidOnline(paymentTypes)) {
    return (
      settings.get(settingsParams.onlinePaymentButtonText) ||
      actionLabels?.onlinePaymentLabel!
    );
  } else {
    return (
      settings.get(settingsParams.offlinePaymentButtonText) ||
      actionLabels?.offlinePaymentLabel!
    );
  }
};

const canBePaidOnline = (paymentTypes: PaymentType[]) =>
  paymentTypes?.length === 1 && paymentTypes?.includes(PaymentType.ONLINE);

export default BookButton;
