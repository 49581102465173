import { useSettings } from '@wix/yoshi-flow-editor/build/cjs/tpa-settings/react';
import React from 'react';
import { Badge, BADGE_PRIORITY } from 'wix-ui-tpa/Badge';
import settingsParams from '../../settingsParams';
import { classes } from './VideoConferenceBadge.st.css';
import { ReactComponent as CameraSVG } from 'wix-ui-tpa/dist/src/assets/icons/Camera.svg';
import { useTranslation } from '@wix/yoshi-flow-editor';
import { VideoConferenceBadgeDataHooks } from './dataHooks';

export interface VideoConferenceBadgeProps {
  videoConferenceProviderId?: string;
}

const VideoConferenceBadge: React.FC<VideoConferenceBadgeProps> = ({
  videoConferenceProviderId,
}) => {
  const { t } = useTranslation();
  const settings = useSettings();
  const videoConferenceBadgeVisibility = settings.get(
    settingsParams.videoConferenceBadgeVisibility,
  );

  const shouldShowVideoBadgeText =
    videoConferenceBadgeVisibility && videoConferenceProviderId;

  return shouldShowVideoBadgeText ? (
    <div className={classes.root}>
      <Badge
        data-hook={VideoConferenceBadgeDataHooks.VIDEO_CONFERENCE_BADGE}
        priority={BADGE_PRIORITY.light}
        icon={<CameraSVG />}
        className={classes.videoConferenceBadge}
      >
        {t('app.booking-details.video-conference-available-online')}
      </Badge>
    </div>
  ) : null;
};

export default VideoConferenceBadge;
