import Text, { TextType } from '../../Text/Text';
import { classes, st } from './SectionTitle.st.css';
import settingsParams from '../../settingsParams';
import { AccessibilityHtmlTags } from '../../../../utils/AccessibilityHtmlTags.const';
import React from 'react';
import { useSettings } from '@wix/yoshi-flow-editor/build/cjs/tpa-settings/react';
import { SectionTitleDataHooks } from './dataHooks';
import { Divider } from 'wix-ui-tpa/Divider';

export type SectionTitleProps = {
  label: string;
};

export function SectionTitle({ label }: SectionTitleProps) {
  const settings = useSettings();

  return (
    <div className={classes.root}>
      <Text
        type={TextType.Title}
        data-hook={SectionTitleDataHooks.LABEL}
        className={st(classes.title, {
          textAlignment: settings.get(settingsParams.titlesAlignment),
        })}
        tagName={AccessibilityHtmlTags.SecondaryHeading}
      >
        {label}
      </Text>
      <Divider
        className={classes.divider}
        data-hook={SectionTitleDataHooks.DIVIDER}
      />
    </div>
  );
}
