import React from 'react';
import { classes, st } from './Summary.st.css';
import { Divider } from 'wix-ui-tpa/Divider';
import {
  TFunction,
  useEnvironment,
  useTranslation,
} from '@wix/yoshi-flow-editor';
import { SummaryDataHooks } from './dataHooks';
import { PriceUtils } from '@wix/bookings-uou-mappers';
import { ServicePayment } from '@wix/bookings-uou-types';
import { isCustomPrice } from '../../../../../utils/payment/payment';
import Text, { TextType } from '../../../Text/Text';

export enum PriceSummaryType {
  Total = 'TOTAL',
  Deposit = 'DEPOSIT',
  SingleSession = 'SINGLE_SESSION',
  CouponDiscount = 'COUPON_DISCOUNT',
}

export type PriceSummary = {
  label?: string;
  type?: PriceSummaryType;
  amount?: string;
};

export interface PriceSummaryProps {
  paymentOptionsLength: number;
  servicePayment: ServicePayment;
  dateRegionalSettingsLocale: string;
  numberOfParticipants: number;
  couponDiscount: number;
}

const Summary: React.FC<PriceSummaryProps> = ({
  paymentOptionsLength,
  servicePayment,
  dateRegionalSettingsLocale,
  numberOfParticipants,
  couponDiscount,
}) => {
  const { isMobile } = useEnvironment();
  const { t } = useTranslation();
  const priceSummary: PriceSummary[] = getPriceSummary(
    servicePayment,
    dateRegionalSettingsLocale,
    numberOfParticipants,
    couponDiscount!,
    paymentOptionsLength,
    t,
  );

  return priceSummary.length ? (
    <>
      {paymentOptionsLength > 1 ? (
        <Divider className={classes.divider} />
      ) : null}
      <div
        data-hook={SummaryDataHooks.PRICE_SUMMARY_WRAPPER}
        className={st(classes.root, { isMobile })}
      >
        {priceSummary?.map((priceSummaryItem) => {
          const textType =
            priceSummaryItem.type === PriceSummaryType.Total ||
            priceSummaryItem.type === PriceSummaryType.Deposit
              ? TextType.Primary
              : TextType.Secondary;
          return (
            <div
              key={priceSummaryItem.label}
              className={`${classes.priceSummaryItem}`}
              data-hook={SummaryDataHooks.PRICE_SUMMARY}
            >
              <div>
                <Text
                  type={textType}
                  data-hook={`summary-${priceSummaryItem.type}`}
                >
                  {priceSummaryItem.label}
                </Text>
              </div>
              {priceSummaryItem.amount ? (
                <div>
                  <Text type={textType} key={priceSummaryItem.label}>
                    {priceSummaryItem.amount}
                  </Text>
                </div>
              ) : null}
            </div>
          );
        })}
      </div>
    </>
  ) : null;
};

const getPriceSummary = (
  servicePayment: ServicePayment,
  dateRegionalSettingsLocale: string,
  numberOfParticipants: number,
  couponDiscount: number,
  paymentOptionsLength: number,
  t: TFunction,
): PriceSummary[] => {
  const amount = servicePayment.paymentDetails.price;
  const currency = servicePayment.paymentDetails.currency;
  const locale = dateRegionalSettingsLocale;
  const priceSummary = [];
  if (couponDiscount > 0) {
    const label = t('app.payment.summary.promo-code.text');
    const type = PriceSummaryType.CouponDiscount;
    const coupon = getSummaryDetail({
      amount: -couponDiscount,
      currency,
      numberOfParticipants,
      locale,
      label,
      type,
    });
    priceSummary.push(coupon);
  }
  if (numberOfParticipants > 1) {
    const label = t('app.payment.options.single-session.text');
    const type = PriceSummaryType.SingleSession;
    const singleSessionPayment = getSummaryDetail({
      amount,
      currency,
      numberOfParticipants,
      locale,
      label,
      type,
    });
    priceSummary.push(singleSessionPayment);
  }
  if (!isCustomPrice(servicePayment)) {
    const label = t('app.payment.summary.total.text');
    const type = PriceSummaryType.Total;
    const calculatedTotalprice =
      numberOfParticipants * servicePayment.paymentDetails.price -
      couponDiscount;
    const totalPayment = getSummaryDetail({
      amount: calculatedTotalprice,
      currency,
      numberOfParticipants,
      locale,
      label,
      type,
    });
    priceSummary.push(totalPayment);
  }
  if (servicePayment.paymentDetails.minCharge) {
    const label = t('app.payment.summary.deposit.text');
    const type = PriceSummaryType.Deposit;
    const calculatedPrice = servicePayment.paymentDetails.minCharge;
    const depositPayment = getSummaryDetail({
      amount: calculatedPrice,
      currency,
      numberOfParticipants,
      locale,
      label,
      type,
    });
    priceSummary.push(depositPayment);
  }
  return priceSummary;
};

const getSummaryDetail = ({
  label,
  amount,
  currency,
  locale,
  numberOfParticipants,
  type,
}: {
  currency: string;
  locale: string;
  label: string;
  amount: number;
  numberOfParticipants: number;
  type: PriceSummaryType;
}): PriceSummary => {
  const numberOfParticipantsPrefix =
    numberOfParticipants > 1 && type === PriceSummaryType.SingleSession
      ? `${numberOfParticipants} X `
      : ``;
  return {
    label,
    amount:
      numberOfParticipantsPrefix +
      PriceUtils.getFormattedCurrency({
        price: amount,
        currency,
        locale,
      }),
    type,
  };
};

export default Summary;
