import React from 'react';
import { classes, st } from './PaymentDetails.st.css';
import { PaymentDetailsDataHooks } from './dataHooks';
import { PaymentOption } from '../PaymentOptions/PaymentOptions';
import Text, { TextType } from '../../../Text/Text';

export interface PaymentDetailsProps {
  paymentDetails: PaymentOption;
  priceText: string;
}

const PaymentDetails: React.FC<PaymentDetailsProps> = ({
  paymentDetails,
  priceText,
}) => {
  return (
    <div
      className={st(classes.root)}
      data-hook={PaymentDetailsDataHooks.PAYMENT_DETAILS}
    >
      {priceText ? (
        <CustomPrice priceText={priceText} />
      ) : (
        <Membership paymentDetails={paymentDetails} />
      )}
    </div>
  );
};

const CustomPrice = ({ priceText }: { priceText: string }) => {
  return (
    <Text
      type={TextType.Secondary}
      data-hook={PaymentDetailsDataHooks.CUSTOM_PRICE}
    >
      {priceText}
    </Text>
  );
};

const Membership = ({ paymentDetails }: { paymentDetails: PaymentOption }) => {
  return (
    <>
      <div className={classes.membership}>
        <Text
          type={TextType.Secondary}
          data-hook={PaymentDetailsDataHooks.MEMBERSHIP}
        >
          {paymentDetails?.label}
        </Text>
        {paymentDetails?.suffix ? (
          <Text
            type={TextType.Secondary}
            data-hook={PaymentDetailsDataHooks.MEMBERSHIP_CREDIT}
          >
            {paymentDetails?.suffix}
          </Text>
        ) : null}
      </div>
      <div>
        <Text
          type={TextType.Secondary}
          data-hook={PaymentDetailsDataHooks.MEMBERSHIP_VALIDATION}
        >
          {paymentDetails?.validUntil}
        </Text>
      </div>
    </>
  );
};
export default PaymentDetails;
