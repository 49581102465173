import React from 'react';
import {
  TFunction,
  useEnvironment,
  useTranslation,
} from '@wix/yoshi-flow-editor';
import { SectionNotification } from 'wix-ui-tpa/SectionNotification';
import { ReactComponent as Alert } from '../../../../../assets/icons/Alert.svg';
import { classes, st } from './Warning.st.css';
import { WarningDataHooks } from './dataHooks';
import { ServicePayment } from '@wix/bookings-uou-types';
import { PaymentOption } from '../PaymentOptions/PaymentOptions';
import { PaidPlans } from '@wix/ambassador-checkout-server/types';
import {
  hasMoreParticipantsThenCredit,
  hasPurchasedPlans,
  isOfferedAsPricingPlan,
} from '../../../../../utils/payment/payment';
import { ReservedPaymentOptionIds } from '../../../../../types/types';

export interface WarningProps {
  numberOfParticipants: number;
  pricingPlanDetails?: PaidPlans;
  servicePayment: ServicePayment;
  paymentOptions: PaymentOption[];
  selectedPaymentOptionId: string;
}

const Warning: React.FC<WarningProps> = ({
  numberOfParticipants,
  pricingPlanDetails,
  servicePayment,
  paymentOptions,
  selectedPaymentOptionId,
}) => {
  const { t } = useTranslation();
  const { isMobile } = useEnvironment();

  const warning = getWarning({
    numberOfParticipants,
    pricingPlanDetails,
    servicePayment,
    paymentOptions,
    selectedPaymentOptionId,
    t,
  });

  return warning ? (
    <div className={st(classes.root, { isMobile })}>
      <SectionNotification type="alert" data-hook={WarningDataHooks.WARNING}>
        <SectionNotification.Icon icon={<Alert />} />
        <SectionNotification.Text className={st(classes.primaryText)}>
          {t(warning)}
        </SectionNotification.Text>
      </SectionNotification>
    </div>
  ) : null;
};

const getWarning = ({
  numberOfParticipants,
  pricingPlanDetails,
  servicePayment,
  paymentOptions,
  selectedPaymentOptionId,
  t,
}: {
  numberOfParticipants: number;
  pricingPlanDetails?: PaidPlans;
  servicePayment?: ServicePayment;
  paymentOptions: PaymentOption[];
  selectedPaymentOptionId?: ReservedPaymentOptionIds | string;
  t: TFunction;
}) => {
  let warning = '';
  const pricingPlan = pricingPlanDetails?.plans?.find(
    (plan) => plan.paidPlan?.planId === selectedPaymentOptionId,
  );

  if (
    hasMoreParticipantsThenCredit(
      pricingPlan?.creditRemain!,
      numberOfParticipants,
    )
  ) {
    const isSinglePaymentOption = paymentOptions.length === 1;
    const hasADisabledPaymentOption = paymentOptions.find(
      (plan) => plan.disabled,
    );
    if (isSinglePaymentOption) {
      warning = t(
        'app.payment.warning.not-enough-sessions-left-reduce-participans-number.text',
      );
    } else if (hasADisabledPaymentOption) {
      warning = t(
        'app.payment.warning.not-enough-sessions-left-pay-single-session.text',
      );
    }
  } else if (
    isOfferedAsPricingPlan(servicePayment!) &&
    !hasPurchasedPlans(pricingPlanDetails!) &&
    selectedPaymentOptionId === ReservedPaymentOptionIds.BuyAPricingPlan &&
    paymentOptions.length === 1
  ) {
    warning = t('app.payment.warning.buy-a-pricing-plan.text');
  }
  return warning;
};

export default Warning;
