import React from 'react';
import { useTranslation } from '@wix/yoshi-flow-editor';
import { classes, st } from './Payment.st.css';
import { PaymentDataHooks } from './dataHooks';
import { Divider } from 'wix-ui-tpa/Divider';
import { PaidPlans } from '@wix/ambassador-checkout-server/types';
import { DateTimeFormatter } from '@wix/bookings-date-time';
import { ServicePayment, ServicePaymentDto } from '@wix/bookings-uou-types';
import { PaymentDtoMapper } from '@wix/bookings-uou-mappers';
import {
  hasMoreParticipantsThenCredit,
  hasPurchasedPlans,
  isServiceHavePricingPlans,
  isOfferedAsOneTime,
  isCustomPrice,
  isFixedPrice,
  isNoFeeSingleSessionService,
} from '../../../../utils/payment/payment';
import Summary from './Summary/Summary';
import {
  PaymentVariationType,
  ReservedPaymentOptionIds,
  TFunction,
} from '../../../../types/types';
import PaymentOptions, { PaymentOption } from './PaymentOptions/PaymentOptions';
import Coupon from './Coupon/Coupon';
import Warning from './Warning/Warning';
import PaymentDetails from './PaymentDetails/PaymentDetails';
import Text, { TextType } from '../../Text/Text';
import { FormErrors } from '../../../../utils/state/initialStateFactory';

export interface PaymentProps {
  servicePayment: ServicePayment;
  numberOfParticipants: number;
  pricingPlanDetails?: PaidPlans;
  dateRegionalSettingsLocale: string;
  isPricingPlanInstalled: boolean;
  selectedPaymentOptionId: string;
  dateAndTimeFormatter: DateTimeFormatter;
  hasCoupon: boolean;
  couponDiscount: number;
  errors: FormErrors[];
  showCouponInput: boolean;
}

const Payment: React.FC<PaymentProps> = ({
  servicePayment,
  numberOfParticipants,
  pricingPlanDetails,
  dateRegionalSettingsLocale,
  isPricingPlanInstalled,
  selectedPaymentOptionId,
  dateAndTimeFormatter,
  hasCoupon,
  couponDiscount,
  errors,
  showCouponInput,
}) => {
  const { t } = useTranslation();
  const paymentOptions: PaymentOption[] = getPaymentOptions({
    servicePayment,
    numberOfParticipants,
    pricingPlanDetails,
    dateRegionalSettingsLocale,
    isPricingPlanInstalled,
    selectedPaymentOptionId,
    dateAndTimeFormatter,
    t,
  });

  const isSingleSessionSelected =
    selectedPaymentOptionId === ReservedPaymentOptionIds.SingleSession;

  const shouldShowCoupon =
    hasCoupon && isFixedPrice(servicePayment) && isSingleSessionSelected;

  return (
    <div className={classes.root}>
      {shouldDisplayPaymentSection(paymentOptions, servicePayment) ? (
        <div data-hook={PaymentDataHooks.PAYMENT_WRAPPER}>
          <Divider
            className={classes.divider}
            data-hook={PaymentDataHooks.DIVIDER}
          />
          <PaymentTitle />

          {paymentOptions.length > 1 ? (
            <PaymentOptions paymentOptions={paymentOptions} />
          ) : isSingleMembership(paymentOptions) ||
            isCustomPrice(servicePayment) ? (
            <PaymentDetails
              paymentDetails={paymentOptions[0]}
              priceText={servicePayment.paymentDetails.priceText}
            />
          ) : null}

          <Warning
            numberOfParticipants={numberOfParticipants}
            paymentOptions={paymentOptions}
            servicePayment={servicePayment}
            selectedPaymentOptionId={selectedPaymentOptionId}
            pricingPlanDetails={pricingPlanDetails}
          />

          {shouldShowCoupon ? (
            <div data-hook={PaymentDataHooks.COUPON_WRAPPER}>
              <Divider
                className={classes.divider}
                data-hook={PaymentDataHooks.DIVIDER}
              />
              <Coupon
                couponDiscount={couponDiscount}
                errors={errors}
                showCouponInput={showCouponInput}
              />
            </div>
          ) : null}
        </div>
      ) : null}

      {shouldShowSummary(
        couponDiscount,
        servicePayment,
        isSingleSessionSelected,
      ) ? (
        <Summary
          paymentOptionsLength={paymentOptions.length}
          servicePayment={servicePayment}
          dateRegionalSettingsLocale={dateRegionalSettingsLocale}
          numberOfParticipants={numberOfParticipants}
          couponDiscount={couponDiscount}
        />
      ) : null}
    </div>
  );
};

const PaymentTitle: React.FC = () => {
  const { t } = useTranslation();
  return (
    <div className={st(classes.paymentTitleWrapper)}>
      <Text type={TextType.Primary} data-hook={PaymentDataHooks.TITLE}>
        {t('app.settings.text.form.payment.title')}
      </Text>
    </div>
  );
};

const isSingleMembership = (paymentOptions?: PaymentOption[]) =>
  paymentOptions &&
  paymentOptions?.[0]?.paymentType === PaymentVariationType.PP;

const shouldDisplayPaymentSection = (
  paymentOptions: PaymentOption[],
  servicePayment: ServicePayment,
) => {
  const pricingPlans = servicePayment?.pricingPlanInfo?.pricingPlans;
  return (
    (paymentOptions && paymentOptions.length > 0) ||
    (pricingPlans && pricingPlans.length > 0)
  );
};

const shouldShowSummary = (
  couponDiscount: number,
  servicePayment: ServicePayment,
  isSingleSessionSelected: boolean,
) => {
  const minCharge = servicePayment.paymentDetails.minCharge;
  return (
    isSingleSessionSelected &&
    (couponDiscount ||
      minCharge ||
      isCustomPrice(servicePayment) ||
      isFixedPrice(servicePayment))
  );
};

export const getPaymentOptions = ({
  servicePayment,
  numberOfParticipants,
  pricingPlanDetails,
  dateRegionalSettingsLocale,
  isPricingPlanInstalled,
  selectedPaymentOptionId,
  dateAndTimeFormatter,
  t,
}: {
  servicePayment: ServicePayment;
  numberOfParticipants: number;
  pricingPlanDetails?: PaidPlans;
  dateRegionalSettingsLocale: string;
  isPricingPlanInstalled: boolean;
  selectedPaymentOptionId: string;
  dateAndTimeFormatter: DateTimeFormatter;
  t: TFunction;
}): PaymentOption[] => {
  const pricingPlans =
    pricingPlanDetails?.plans?.map((plan) => {
      const disabled = hasMoreParticipantsThenCredit(
        plan.creditRemain!,
        numberOfParticipants,
      );
      return {
        id: plan.paidPlan?.planId,
        checked: plan.paidPlan?.planId === selectedPaymentOptionId,
        label: plan.planName,
        paymentType: PaymentVariationType.PP,
        ...(plan.creditRemain && plan.creditOriginal
          ? { suffix: plan.creditRemain + '/' + plan.creditOriginal }
          : {}),
        ...(plan.validUntil
          ? {
              validUntil: t('app.payment.valid-until.text', {
                validUntil: dateAndTimeFormatter.formatDateAndTime(
                  plan.validUntil,
                ),
              }),
            }
          : {}),
        disabled,
      };
    }) || [];

  const paymentOptions = [...pricingPlans];
  if (
    isPricingPlanInstalled &&
    isServiceHavePricingPlans(servicePayment) &&
    !hasPurchasedPlans(pricingPlanDetails)
  ) {
    const buyAPricingPlanOption = {
      id: ReservedPaymentOptionIds.BuyAPricingPlan,
      checked:
        ReservedPaymentOptionIds.BuyAPricingPlan === selectedPaymentOptionId,
      label: t('app.payment.options.buy-a-pricing-plan.text'),
      paymentType: PaymentVariationType.BuyPP,
      validUntil: '',
      suffix: '',
      disabled: false,
    };
    paymentOptions.push(buyAPricingPlanOption);
  }
  if (
    isOfferedAsOneTime(servicePayment) &&
    !isNoFeeSingleSessionService(servicePayment)
  ) {
    const singleSession = {
      id: ReservedPaymentOptionIds.SingleSession,
      checked:
        ReservedPaymentOptionIds.SingleSession === selectedPaymentOptionId,
      label: t('app.payment.options.single-session.text'),
      suffix: getPriceText(servicePayment, dateRegionalSettingsLocale),
      paymentType: PaymentVariationType.Fixed,
      disabled: false,
    };
    paymentOptions.push(singleSession);
  }

  return paymentOptions;
};

const getPriceText = (
  payment: ServicePayment,
  dateRegionalSettingsLocale: string,
): string => {
  const paymentDto: ServicePaymentDto = payment.paymentDetails;
  const paymentDtoMapper = new PaymentDtoMapper(dateRegionalSettingsLocale);
  return paymentDtoMapper.priceText(paymentDto);
};

export default Payment;
