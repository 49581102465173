import { Text, TextProps } from 'wix-ui-tpa/Text';
import React from 'react';
import { classes, st } from './Text.st.css';
import { useEnvironment } from '@wix/yoshi-flow-editor';

export enum TextType {
  Title = 'title',
  Primary = 'primary',
  Secondary = 'secondary',
}

type TextComponentProps = {
  type: TextType;
} & TextProps;

const TextComponent: React.FC<TextComponentProps> = (props) => {
  const {
    type,
    tagName,
    className,
    children,
    'data-hook': dataHook,
    ...rest
  } = props;
  const { isMobile } = useEnvironment();

  return (
    <Text
      className={st(classes.root, { isMobile, type }, className)}
      data-hook={dataHook}
      tagName={tagName}
      {...rest}
    >
      {children}
    </Text>
  );
};

export default TextComponent;
